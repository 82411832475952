<template>
    <div class="ui-switcher">
        <p v-if="offLabel">{{offLabel}}</p>
        <div class="ui-switcher-container" :class="{'checked': valueCheck, 'disabled': disabled}"
        @click="checkValue()">
            <div class="ui-switcher-container__slider round"></div>
        </div>
        <p v-if="onLabel">{{onLabel}}</p>
    </div>
</template>

<script>

export default {

    name: 'switcher',
    props: {

        value: {
            type: Boolean,
            default: false,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        offLabel: {
            type: String,
            default: '',
        },

        onLabel: {
            type: String,
            default: '',
        },

    },
    data(){
        return {
            valueCheck: false,
        };
    },
    watch:{

        value(){
            this.valueCheck = this.value;
        },

    },
    methods: {

        checkValue(){

            if (this.disabled) return;

            if (this.valueCheck){
                this.valueCheck = false;
            } else {
                this.valueCheck = true;
            }

            this.$emit('input', this.valueCheck);

        },

    },
    created(){
        this.valueCheck = this.value;
    },

};

</script>

<style lang="scss" src="./switcher.scss" />
