<template>
    <div class="view-welcome">
        <div class="welcome-container">
            <div class="welcome-container__image">
                <img class="woman-ilustration" src="~@/assets/images/decorations/hero-woman.svg" alt="Woman" />
                <div class="phone-box">
                    <img src="~@/assets/images/decorations/hero-phone.svg" alt="Mobile phone" />
                </div>
                <img class="man-box" src="~@/assets/images/decorations/hero-man.svg" alt="Man" />
            </div>
            <div class="welcome-container__content">
                <h1>{{facility ? facility.name : ''}}</h1>
                <searchForm
                v-if="facility"
                :type="visitType"
                :kind="visitKind"
                :facility="facility"
                :data="getSavedData"
                @search="saveSearch($event)"/>
            </div>
        </div>
    </div>
</template>

<script>

import { mapState, mapActions } from 'vuex';
import findTerms from '@/mixins/local/findTerms';

import searchForm from '@/components/functional/searchForm/searchForm.vue';

export default {
    
    name: 'Welcome',
    mixins: [findTerms],
    components: {

        searchForm,

    },
    methods: {

        ...mapActions('global', [
            'setCity',
            'setDoctor',
            'setSpeciality',
            'setVisitDate',
            'setVisitType',
            'setVisitKind',
        ]),

        saveSearch(data){

            this.setCity(data.city);
            this.setDoctor(data.doctor);
            this.setSpeciality(data.speciality);
            this.setVisitDate(data.visitDate);
            this.setVisitType(data.visitType);
            this.setVisitKind(data.visitKind);

            this.app__findFreeTerms();

        },

    },
    computed: {
        ...mapState('user', ['facility']),
        ...mapState('global', [
            'cities',
            'doctors',
            'specialities',
            'selectedCity',
            'selectedDoctor',
            'selectedSpeciality',
            'visitDate',
            'visitType',
            'visitKind',
        ]),

        getSavedData(){

            return {
                cities: this.cities,
                doctors: this.doctors,
                specialities: this.specialities,
                selectedCity: this.selectedCity,
                selectedDoctor: this.selectedDoctor,
                selectedSpeciality: this.selectedSpeciality,
                visitDate: this.visitDate,
            };

        },
    },

};

</script>

<style lang="scss" src="./Welcome.scss" />
